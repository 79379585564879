import React from 'react'
import PropagateLoader from "react-spinners/PropagateLoader";

import { getApi, postApi } from '../../../modules/api'

import { AppContext } from '../../../contexts/appContext'

const RequestNumber = () => {
  const { state, dispatch  } = React.useContext(AppContext)
  const [ buttonDisabled, setButtonDisabled ] = React.useState(true)

  const requestMobileNumber = () => {
    dispatch({ type: 'LOADING' })
    postApi('mobiles').then(() => {
      getApi('mobiles')
        .then(res => {
          dispatch({ type: "RETRIEVE_NUMBERS", payload: res.body.mobileNumbers })
          dispatch({ type: "SUCCESS" })
        })
        .catch(err => dispatch({ type: 'ERROR', payload: err }))
    }).catch((err) => {
      dispatch({ type: 'ERROR', payload: err })
    })
  }

  React.useEffect(() => {
    const invalidNumbers = state.mobileNumbers.filter(({ mobileNumber }) => mobileNumber ? false : true )

    setButtonDisabled(invalidNumbers.length > 0 ? true : false)
  }, [state.mobileNumbers])

  return (
    <div className="flex justify-center rounded-b-md">
      { state.mobileNumbers?.length > 0 
        ?
          state.loading
          ? <PropagateLoader color={"#F5F5F5"} loading={state.loading} size={10} />
          : buttonDisabled
            ? (
              <div className="flex items-center justify-around w-3/4 p-2 m-auto text-sm leading-normal text-white rounded-lg">
                We've got your request for a number and will email you within 24 hours once it is allocated.
              </div>
            )
            : (
              <button disabled={buttonDisabled} onClick={requestMobileNumber} className={`${buttonDisabled ? 'cursor-not-allowed' : ''} focus:outline-none bg-white p-2 w-2/3 m-auto flex justify-around rounded-lg text-primary text-sm items-center leading-normal text-center`}> 
                <svg className="text-primaryText MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"></path>
                </svg> 
                Request Number
              </button>
            )
        : <button disabled={buttonDisabled} onClick={requestMobileNumber} className={`${buttonDisabled ? 'cursor-not-allowed' : ''} focus:outline-none bg-white p-2 w-2/3 m-auto flex justify-around rounded-lg text-primary text-sm items-center leading-normal text-center`}> 
            <svg className="text-primaryText MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"></path>
            </svg> 
            Request Number
          </button>
      }
      
    </div>
  )
}

export default RequestNumber
