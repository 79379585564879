import React from "react"
import { DateTime } from "luxon"

const MessageCard = ({ selected, onClick, message }) => {
  const selectedCss = selected 
    ? "bg-gray-300"
    : "hover:bg-gray-300 bg-white"

  const localTime = message.createdAt && DateTime.fromJSDate(new Date(message.createdAt))?.toFormat('dd LLL yyyy')

  return (
    <button onClick={onClick} className={`${selectedCss} focus:outline-none border-b-2 text-left flex items-center px-4 py-4 space-x-2`}>
      <div className="flex flex-col items-start w-full h-full">
        <div className="w-full text-base font-medium leading-6 text-gray-900 truncate h-3/4">{message.content}</div>
        <div className="flex justify-between w-full h-1/4"> 
          <div className="text-sm leading-normal text-gray-500">{message.sender}</div>
          <div className="text-sm leading-normal text-gray-500">{localTime}</div>
        </div>
      </div>
    </button>
  )
}

export default MessageCard
