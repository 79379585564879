import React, { useEffect, useRef, useState, useContext } from 'react';
import MoonLoader from "react-spinners/MoonLoader";

// Import Components
import Modal from '../../../../components/Modal'

// Import Modules
import { postApi } from '../../../../modules/api'

// Import Contexts
import { OrgContext } from '../../../../contexts/orgContext'

// Logo Render
export default function CreateOrganisationComponent (props) {
  const { toggleMethod, selectOrganisation } = props
  const { state, dispatch } = useContext(OrgContext)
  const [orgName, setOrgName] = useState('')

  const createOrganisation = async () => {
    dispatch({ type: "LOADING"})
    try {
      const response = await postApi('createOrganisation', { orgName })
      dispatch({ type: "ADD_ORGANISATION", payload: response.body.newOrganisation })
      toggleMethod(false)
      selectOrganisation(response.body.newOrganisation)
    } catch (err) {
      dispatch({ type: "FAILURE"})
      console.log("FOUND ERROR: ", err)
    }
  }

  return (
    <Modal toggleMethod={props.toggleMethod}>
      <div className="px-4 pt-5 pb-4 bg-primary sm:p-6 sm:pb-4">
        {/* <div className="sm:flex sm:items-start"> */}
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 className="text-lg font-bold leading-normal leading-6 text-primaryText" id="modal-headline">
            Create Organisation
          </h3>
          <div className="mt-2">
            <input autoComplete="on" value={orgName} onChange={(e) => setOrgName(e.target.value)} aria-label="Organisation Name" name="orgName" type="text" className={"rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 my-1 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 leading-normal focus:z-10 sm:text-sm sm:leading-5"} placeholder="Organisation Name" />
          </div>
        </div>
        {/* </div> */}
      </div>
      <div className="px-4 py-3 pb-8 bg-primary sm:px-6 sm:flex sm:flex-row-reverse">
        <div className="inline-flex justify-center w-full px-4 py-2 text-base font-bold leading-normal bg-white border border-transparent rounded-md shadow-sm text-primaryText focus:outline-none sm:ml-3 sm:w-auto sm:text-sm">
          {state.loading 
            ? <MoonLoader color={"#F46036"} loading={state.loading} size={10} />
            : <button type="button" onClick={createOrganisation}> {"Submit"} </button>
          }
        </div>
        <button onClick={() => toggleMethod(false)} type="button" className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium leading-normal text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
          Cancel
        </button>
      </div>
    </Modal>
  )
}
