import React, { useContext, useEffect, useState } from 'react'
import PuffLoader from "react-spinners/PuffLoader";
import { CheckCircleOutline, HighlightOff, Mail, Delete } from '@material-ui/icons';

// Import Context
import OrgProvider, { OrgContext } from '../../../contexts/orgContext'

// Import Utilities
import { postApi, getApi } from '../../../modules/api'

// Import Components
import AddUserPopup from './addUserPopup'


const OrganisationTabContents = (props) => {
  const { state: orgState, dispatch: orgDispatch } = useContext(OrgContext)
  const [displayPopup, setDisplayPopup] = useState(false)

  const getUsers = () => {
    orgDispatch({ type: "LOADING"})
    getApi('getUsers', {}).then(res => {
      orgDispatch({ type: "STORE_USERS_IN_ORGANISATION", payload: res.body.users})
    }).catch(err => {
      console.log("Found Error: ", err)
      orgDispatch({ type: "ERROR", payload: err })
    })
  }

  const deleteUser = ({ userId }) => {
    orgDispatch({ type: "LOADING"})
    postApi('removeUserFromOrganisation', { userId }).then(res => {
      orgDispatch({ type: "UPDATE_STORED_USERS", payload: { userId } })
    }).catch(err => {
      orgDispatch({ type: "ERROR", payload: err })
    })
  }

  useEffect(() => {
    getUsers()
  }, [] )

  const LoadingSpinner = () => {
    return (
      <div className="flex justify-center mt-4">
        <PuffLoader />
      </div>
    )
  }

  const ListUsersInOrganisation = () => {
    return (
      <div>
        {orgState.users?.map((user) => (
          <li key={user.email}>
            <div className="flex items-center pt-4">
              <div className="flex items-center flex-1 min-w-0">
                <div className="flex-1 min-w-0 px-4 pt-4 border-t-2 md:grid md:grid-cols-3 md:gap-4 border-secondary">
                  <div>
                    <p className="text-sm font-bold leading-normal truncate text-primary">{`${user.firstName} ${user.lastName}`}</p>
                    <p className="flex items-center mt-2 text-xs text-gray-500">
                      <Mail className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span className="truncate">{user.email}</span>
                    </p>
                  </div>
                  <div className="hidden md:block">
                    <div>
                      <p className="flex text-xs leading-normal text-gray-900 justify-evenly">
                        <strong>Role:</strong> {user.role}
                      </p>
                      <p className="flex items-center mt-2 text-xs leading-normal text-gray-500 justify-evenly">
                        <strong>Active:</strong> 
                        {user.active ? <CheckCircleOutline className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" aria-hidden="true" /> : <HighlightOff className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-500" aria-hidden="true" /> }
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-start w-20">
                    <button onClick={() => deleteUser(user)}><Delete className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-500" aria-hidden="true" /></button>
                  </div>
                </div>
              </div>
            </div>
          </li>
        )) || "No Users Found"}
      </div>
    )
  }

  return (
    <div className="flex flex-col mx-6">
      <div className="flex justify-between">
        <div>
          <h2 className="text-lg font-medium leading-6 text-gray-900">Organisation</h2>
          <p className="mt-1 text-sm text-gray-500">
            Manage your organisation members below
          </p>
        </div>
        <div className="flex px-3 py-2 text-sm text-white bg-green-600 rounded-md outline-none focus:outline-none">
          <button className="outline-none" onClick={() => setDisplayPopup(true)}>Add User</button>
        </div>
      </div>

      <div className="w-full mt-6">
        {
          orgState.loading ? (<LoadingSpinner />) : (
            <ul className="divide-y divide-gray-200">
              {ListUsersInOrganisation()}
            </ul>
          )
        }
      </div>

      {displayPopup && <AddUserPopup toggleMethod={setDisplayPopup} postSubmitMethod={getUsers} />}
    </div>
  )
}

export default function OrganisationTab(props) {
  return (
    <OrgProvider>
      <OrganisationTabContents {...props} />
    </OrgProvider>
  )
}
