import React, { createContext, useReducer } from 'react';
import jwt from 'jsonwebtoken'

export const AuthContext = createContext({});

const initialState = () => {
  const accessToken = localStorage.getItem('accessToken') || null
  const refreshToken = localStorage.getItem('refreshToken') || null

  const { trialMode = '', firstName = '', lastName = '', orgSlug = '', role = '', requestedNumber = false} = accessToken 
    ? jwt.decode(accessToken) 
    : {}

  return {
    loading: false,
    error: null,
    accessToken,
    refreshToken,
    trialMode,
    firstName,
    lastName,
    orgSlug,
    requestedNumber,
    role,
  }
}

const AuthProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch(action.type) {
      case 'LOADING':
        return { ...state, loading: true}
      case 'SUCCESS':
        return { ...state, loading: false}
      case 'ERROR':
        return { ...state, loading: false, error: action.payload}
      case 'AUTH_SUCCESS': 
        const { accessToken, refreshToken } = action.payload
        localStorage.setItem('accessToken', accessToken)
        localStorage.setItem('refreshToken', refreshToken)

        return {
          ...state,
          accessToken, 
          refreshToken, 
          loading: false,
          orgSlug: jwt.decode(accessToken).orgSlug
        }
      case 'NUMBER_REQUESTED': 
        return { 
          ...state,
          requestedNumber: action.payload,
          loading: false
        }
      case 'LOGOUT':
        localStorage.clear()
        return initialState()
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState());

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  )
};

export default AuthProvider
