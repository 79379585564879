import React, { useRef, useEffect } from 'react'

const Modal = ({ children, toggleMethod }) => {
  const wrapperRef = useRef(null);

    // Dismiss popup if you click outside
  useEffect(() => {
    const handleClickOutside = e => {
      if(toggleMethod) {
        if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
          toggleMethod(false)
        }
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [wrapperRef, toggleMethod]);

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        {/* Add grey background to the screen */}
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        {/* Modal */}
        <div ref={wrapperRef} className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal
