import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom'

// Import Components
import Logo from '../../components/Logo'
import Registration from './registration'
import Login from './login'

// Import Utilities
import { postApi } from '../../modules/api'

// Import Contexts
import { AuthContext } from '../../contexts/authContext';

const Auth = (props) => {
  const { state, dispatch } = useContext(AuthContext)
  const history = useHistory()
  const [isRegistering, setIsRegistering] = useState(false)

  const dispatchAuthAction = (data) => {
    dispatch({ type: 'LOADING' })
    postApi(isRegistering ? 'register' : 'login', data)
      .then(({ body: { accessToken, refreshToken } }) => {
        localStorage.setItem('accessToken', accessToken)
        localStorage.setItem('refreshToken', refreshToken)
        dispatch({ type: 'AUTH_SUCCESS', payload: { accessToken, refreshToken } })
        history.push('/mobiles')
      }).catch(err => {
        dispatch({ type: 'ERROR', payload: err })
      })
  }

  const setError = (err) => {
    dispatch({ type: 'ERROR', payload: err })
  }

  const toggleAction = () => setIsRegistering(!isRegistering)
  
  return (
    <div className="flex items-center justify-center min-h-screen px-4 py-12 bg-gray-50 sm:px-6 lg:px-8">
      <div>
        <div className="p-16 border-2 border-black rounded-lg shadow-2xl bg-primary">
          <div className="flex w-auto h-12 mx-auto"><Logo contrast="white"/></div>
          { isRegistering 
            ? <Registration 
                authState={state}
                toggleAction={toggleAction}
                onSubmit={dispatchAuthAction}
                setError={setError}
              />
            : (
              <Login
                authState={state}
                toggleAction={toggleAction}
                onSubmit={dispatchAuthAction}
              />
            )
          }
        </div>
      </div>
    </div>
  )
}

export default Auth
