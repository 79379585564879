import React from "react"
import PropagateLoader from "react-spinners/PropagateLoader";

// Import Components
import NumberCard from "./numberCard"
import RequestNumber from './requestNumber'

import { AppContext } from '../../../contexts/appContext'

const NumberColumn = () => {
  const { state, dispatch  } = React.useContext(AppContext)

  const selectMobileNumber = ({ mobileNumber }) => {
    dispatch({ type: 'SELECT_ACTIVE_NUMBER', payload: mobileNumber })
  }

  return (
    <div className="flex flex-col w-64 flex-3">
      <div className="flex flex-col min-h-screen border-t-2 bg-primary border-primaryText">
        <div className="flex flex-col pt-4 pb-4 overflow-y-auto">
          <div aria-label="Sidebar">
            <div className="px-2 space-y-1">
              { state.mobileNumbers.length
                ? state.mobileNumbers.map((mobileNumber, index) => <NumberCard
                  key={index}
                  onClick={() => selectMobileNumber(mobileNumber)}
                  selected={mobileNumber.mobileNumber === state.activeNumber} 
                  mobileNumber={mobileNumber} 
                /> )
                : (
                  <div className="flex justify-center w-1/2 m-auto text-white">
                    {
                      state.loading
                        ? <div className="flex justify-center p-2"> <PropagateLoader color={"#F5F5F5"} loading={state.loading} size={10} /> </div>
                        : <div>Please request a number below</div>
                    }
                  </div>
                )
              }
            </div>
            <hr className="w-3/4 mx-auto my-4 border-t border-gray-200" aria-hidden="true" />
            <RequestNumber />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NumberColumn
