import React, { createContext, useReducer } from 'react';

export const BillingContext = createContext({});

const initialState = {
  loading: false,
  error: null,
  customerInformation: {}
}

const BillingProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch(action.type) {
      case 'LOADING':
        return { ...state, loading: true}
      case 'SUCCESS':
      return { ...state, loading: false}
      case 'ERROR':
        return { ...state, loading: false, error: action.payload}
      case 'BILLING_RETRIEVED': 
        return {
          ...state, 
          customerInformation: action.payload,
          loading: false
        }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <BillingContext.Provider value={{ state, dispatch }}>
      {children}
    </BillingContext.Provider>
  )
};

export default BillingProvider
