import React, { useEffect, createContext, useReducer } from 'react';

import { getApi } from '../modules/api'

export const AppContext = createContext({});

const initialState = {
  loading: false,
  error: null,
  mobileNumbers: [],
  messages: [],
  activeNumber: null,
  activeMessage: null
}

const AppProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch(action.type) {
      case 'LOADING':
        return { ...state, loading: true}
      case 'ERROR':
        return { ...state, loading: false, error: action.payload}
      case 'RETRIEVE_NUMBERS':
        return {
          ...state,
          mobileNumbers: action.payload,
          activeNumber: state.activeNumber || action.payload[0]?.mobileNumber,
          loading: false
        }
      case 'RETRIEVE_MESSAGES':
        return {
          ...state,
          messages: action.payload,
          activeMessage: state.activeMessage || action.payload[0],
          loading: false
        }
      case 'SELECT_ACTIVE_NUMBER': 
        return {
          ...state,
          activeNumber: action.payload,
          activeMessage: null,
          loading: false
        }
      case 'SELECT_ACTIVE_MESSAGE': 
        return {
          ...state,
          activeMessage: action.payload,
          loading: false
        }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: 'LOADING' })
    getApi('mobiles')
      .then(res => dispatch({ type: "RETRIEVE_NUMBERS", payload: res.body.mobileNumbers }))
      .catch(err => dispatch({ type: 'ERROR', payload: err }))
  }, [])

  useEffect(() => {
    if(state.activeNumber) {
      getApi(`smsMessages/${state.activeNumber}`)
        .then(res =>  dispatch({ type: "RETRIEVE_MESSAGES", payload: res.body.messages }))
        .catch(err => dispatch({ type: 'ERROR', payload: err }))
    }
  }, [state.activeNumber])

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  )
};

export default AppProvider
