import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import PropagateLoader from "react-spinners/PropagateLoader";
import queryString from 'query-string';

// Import Components
import Logo from '../../components/Logo'

// Import Utilities
import { postApi } from '../../modules/api'

// Import Contexts
import { AuthContext } from '../../contexts/authContext';

const GoogleAuth = (props) => {
  const { state, dispatch } = useContext(AuthContext)
  const history = useHistory()
  const params = queryString.parse(props.location.search)

  useEffect(() => {
    dispatch({ type: 'LOADING' })
    postApi(`login/google`, { code: params.code })
      .then(({ body: { accessToken, refreshToken } }) => {
        localStorage.setItem('accessToken', accessToken)
        localStorage.setItem('refreshToken', refreshToken)
        dispatch({ type: 'AUTH_SUCCESS', payload: { accessToken, refreshToken } })
        history.push('/mobiles')
      }).catch(err => {
        dispatch({ type: 'ERROR', payload: err })
      })
  }, [])

  return (
    <div className="flex items-center justify-center min-h-screen px-4 py-12 bg-gray-50 sm:px-6 lg:px-8">
      <div>
        <div className="p-16 border-2 border-black rounded-lg shadow-2xl bg-primary">
          <div className="flex w-auto h-12 mx-auto"><Logo contrast="white"/></div>
          <div className="relative flex items-center justify-center pt-8">
            <PropagateLoader color={"#F5F5F5"} loading={state.loading} size={15} /> 
          </div>
        </div>
      </div>
    </div>
  )
}

export default GoogleAuth
