import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropagateLoader from "react-spinners/PropagateLoader";

import NavigationComponent from './Navbar'

// Import Components
import Logo from './Logo'

// Import Contexts
import { AuthContext } from '../contexts/authContext'
import UserProvider from '../contexts/userContext'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { state } = useContext(AuthContext)

  if (state.loading) {
    return (
      <Route
        {...rest}
        render={() => {
          return (
          <div className="flex items-center justify-center min-h-screen px-4 py-12 bg-gray-50 sm:px-6 lg:px-8">
            <div className="w-full max-w-md">
              <div className="p-16 border-2 border-black rounded-lg shadow-2xl bg-primary">
                <div className="flex w-auto h-12 mx-auto"><Logo contrast="white"/></div>
                <div className="flex justify-center pt-4">
                  <PropagateLoader color={"#F5F5F5"} loading={true} css={"display: flex; align-items: center;"} size={10} /> 
                </div>
              </div>
            </div>
          </div>)
        }}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={routeProps => {
        return state.accessToken && state.refreshToken ? (
          <>
            <UserProvider>
              <NavigationComponent />
              <Component { ...routeProps } />
            </UserProvider>
          </>
        ) : (
          <Redirect to="/authenticate" />
        );
      }}
    />
  );
};

export default PrivateRoute;
