import React from 'react';

// Import Contexts
import AppProvider, { AppContext } from '../../contexts/appContext'

// Import Components
import MessagesColumn from './components/messageColumn'
import NumberColumn from './components/numberColumn'

function MobileNumberContents (props) {
  const { state } = React.useContext(AppContext)

  const formatAMPM = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  const MessageDetails = ({ selectedMessage }) => {
    return (
      <article>
        {/* Title */}
        <div className="max-w-5xl px-6 mx-auto lg:px-8">
          <h1 className="mt-6 text-2xl font-bold text-gray-900 truncate">
            Authentication Message
          </h1>
        </div>

        {/* Message Details */}
        <div className="max-w-5xl px-6 mx-auto mt-6 lg:px-8">
          <div className="grid grid-cols-2 gap-x-4 gap-y-8">
            <div className="col-span-2">
              <dt className="text-sm font-semibold text-primary">
                Contents
              </dt>
              <dd className="mt-1 space-y-5 text-sm text-gray-900 max-w-prose">
                <p>{selectedMessage.content}</p>
              </dd>
            </div>

            <div className="col-span-1">
              <dt className="text-sm font-semibold text-primary">
                Sender
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {selectedMessage.sender}
              </dd>
            </div>

            <div className="col-span-1">
              <dt className="text-sm font-semibold text-primary">
                Date
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {new Date(selectedMessage.createdAt).toDateString()}
              </dd>
            </div>

            <div className="col-span-1">
              <dt className="text-sm font-semibold text-primary">
                Time
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {formatAMPM(new Date(selectedMessage.createdAt))}
              </dd>
            </div>
          </div>
        </div>

        {/* <!-- Viewer List --> */}
        <div className="max-w-5xl px-6 pb-12 mx-auto mt-8 lg:px-8">
          <h2 className="text-sm font-semibold text-primary">Viewed By:</h2>
          <div className="flex items-center justify-center w-full h-64 mt-2 bg-gray-300 border-4 border-gray-300 rounded-lg">
            <div className="text-lg font-medium text-primary">
              <span>🚧 Currently Under Construction 🚧</span>
            </div>
          </div>
        </div>
      </article>
    )
  }

  return (
    <div className="flex flex-shrink-0">
      <NumberColumn />
      <MessagesColumn />
      
      <div className="flex-1 w-1/2">
        { state.activeMessage && Object.keys(state.activeMessage).length !== 0
          ? <MessageDetails selectedMessage={state.activeMessage} />
          : <div className="flex flex-col items-center justify-center h-full">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4" />
            </svg>
            <div className="text-primary">
              No Message Selected
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default function MobileNumbersPage(props) {
  return (
    <AppProvider>
      <MobileNumberContents {...props} />
    </AppProvider>
  )
}
