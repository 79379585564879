import React, { useContext } from 'react'

import { UserContext } from '../../contexts/userContext'

const InitialsIconContents = () => {
  const { state } = useContext(UserContext)

  return (
    <div className="bg-white text-primary font-bold flex justify-center h-8 w-8 rounded-full text-center items-center">
      {`${state.firstName.charAt(0)}${state.lastName.charAt(0)}`}
    </div>
  )
}

export default function InitialsIcon(props) {
  return <InitialsIconContents {...props} />
}
