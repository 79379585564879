import React, { useState } from 'react';
import PropagateLoader from "react-spinners/PropagateLoader";

const Registration = ({ authState, toggleAction, onSubmit, plan, setError }) => {
  const { error, loading } = authState
  const [formValues, updateFormValues] = useState({ email: '', firstName: '', password: '', confirmPassword: '' })
  const [invalidKeys, setInvalidKeys] = useState([])
  const [currentStep, setNewStep] = useState(0)
  const requiredKeys = { 0: ['email', 'firstName', 'password', 'confirmPassword'] }

  const updateStep = (step) => {
    setError('')
    setNewStep(step)
  }

  // This will check if there are missing keys. If there are, we add
  // a necessary animation and then remove it along with any errors.
  const communicateMissingKey = (key) => {
    !invalidKeys.includes(key) && invalidKeys.push(key)

    // Clear everything
    const timer = setTimeout(() => {
      const newKeys = invalidKeys.slice(invalidKeys.indexOf(key) + 1)
      setInvalidKeys(newKeys)
      if(!newKeys.length) {
        setError('')
        setInvalidKeys([])
      }
    }, 1000);
    
    return () => clearTimeout(timer);
  }

  const validatePassword = (password, confirmPassword) => {
    if(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/.test(password)) return password === confirmPassword || setError('Passwords must match') || false
    setError('Password must be at least 8 characters with: One Uppercase, One Number and One Special-Character')
    return false
  }

  const validateStep = (action) => {
    const step = currentStep;
    const newStep = action === 'increase' ? step + 1 : step - 1;

    switch(step){
      case 0:
        Object.entries(formValues).map(([key, value]) => {
          if(!value && requiredKeys[step].includes(key)) {
            communicateMissingKey(key)
            return false
          }
          return true
        })

        if (invalidKeys.length) setError('Missing important information') 
        else {
          if(!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(formValues.email)) {
            setError("Invalid Email Provided")
          } else {
            validatePassword(formValues.password, formValues.confirmPassword) && onSubmit(formValues) // updateStep(newStep)
          }
        }
        break;
      case 1:
        action === 'decrease' 
          ? updateStep(newStep) 
          : onSubmit(formValues)
        break;
      default:
        updateStep(newStep)
        break;
    }
  }

  const inputCss = (key) => {
    const defaultClass = "appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 my-1 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5 leading-normal"
    const errorClass = "animate-shake border-8 border-error"
    switch(key) {
      case 'email':
      return `${defaultClass} my-1 rounded-t-md ${invalidKeys.includes(key) && errorClass}`

      case 'firstName':
      return `${defaultClass} ${invalidKeys.includes(key) && errorClass}`

      case 'lastName':
      return `${defaultClass} ${invalidKeys.includes(key) && errorClass}`

      case 'password':
      return `${defaultClass} ${invalidKeys.includes(key) && errorClass}`

      case 'confirmPassword':
      return `${defaultClass} rounded-b-md ${invalidKeys.includes(key) && errorClass}`
      
      default:
      return `${defaultClass}`
    }
  }

  const handleInputChange = (e) => updateFormValues({ ...formValues, [e.target.name]: e.target.value})

  // Define our buttons for the registration steps
  // const DecreaseButton = ({ className, value, method, loading, disabled }) => <button disabled={loading} className={`${className}`} onClick={() => method ? method() : validateStep('decrease')}>{value || "Previous"}</button>
  const IncreaseButton = ({ className, value, method, loading, disabled }) => <button disabled={disabled} className={`${className}`} onClick={() => method ? method() : validateStep('increase')}> { value || "Next" } </button>

  // Registration Details
  const stepOne = (stepTitle) => {
    return (
      <div>
        <h2 className="my-3 text-2xl font-extrabold leading-normal text-center text-white"> {stepTitle} </h2>
        <div className="rounded-md shadow-sm">
          <form>
            <input autoComplete="on" value={formValues.email} aria-label="Email address" name="email" type="email" onChange={handleInputChange} className={inputCss('email')} placeholder="Email Address" />
            <input autoComplete="on" value={formValues.firstName} aria-label="First Name" name="firstName" type="text" onChange={handleInputChange} className={inputCss('firstName')} placeholder="First Name" />
            <input autoComplete="on" value={formValues.lastName} aria-label="Last Name" name="lastName" type="text" onChange={handleInputChange} className={inputCss('lastName')} placeholder="Last Name" />
            <input autoComplete="on" value={formValues.password} aria-label="Password" name="password" type="password" onChange={handleInputChange} className={inputCss('password')} placeholder="Password" />
            <input autoComplete="on" value={formValues.confirmPassword} aria-label="Confirm Password" name="confirmPassword" type="password" onChange={handleInputChange} className={inputCss('confirmPassword')} placeholder="Confirm Password" />
          </form>
        </div>
        <div className={`${!error && 'mt-6'} flex flex-col`}>
          {
            error && <div className="leading-normal text-md text-error">
              {error}
            </div>
          }
          <div className="text-sm leading-normal text-white">
            {"Got an account? "}
            <button onClick={toggleAction} className="font-medium transition duration-150 ease-in-out text-primaryText hover:text-primaryText focus:outline-none focus:underline">
              Login Here
            </button>
          </div>
        </div>
        <div className="mt-6">
          <IncreaseButton disabled={loading} value={ loading 
            ? (
              <div className="relative flex items-center justify-center w-full h-full px-4 py-2 mx-auto text-sm font-medium leading-normal text-white transition duration-150 ease-in-out border border-transparent rounded-md group bg-primaryText hover:bg-primaryText focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700">
                <PropagateLoader color={"#F5F5F5"} loading={loading} css={"display: flex; align-items: center;"} size={15} /> 
              </div>
            )
            : "Submit" 
          } className="relative flex justify-center w-full px-4 py-2 text-sm font-medium leading-normal text-white transition duration-150 ease-in-out border border-transparent rounded-md group bg-primaryText hover:bg-primaryText focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700" />
        </div>
      </div>
    )
  }

  const registrationStepManager = () => {
    const stepTitles = ['Please signup below', 'Select Monthly Plan']

    switch(currentStep) {
      case 0: return stepOne(stepTitles[0])
      default: return stepOne(stepTitles[0])
    }
  }

  return (
    <div>
      { registrationStepManager() }
    </div>
  )
}

export default Registration
