import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { AuthContext } from '../../contexts/authContext'

const LogoutButton = () => {
  const { dispatch } = useContext(AuthContext)
  const history = useHistory()

  const dispatchLogout = () => {
    dispatch({ type: "LOGOUT" })
    history.push('/authenticate')
  }

  return (
    <button 
      onClick={dispatchLogout} 
      className="block px-4 py-2 text-sm leading-normal text-gray-700 hover:bg-gray-100" 
      role="menuitem"
    >
        Sign out
    </button>
  )
}

export default LogoutButton
