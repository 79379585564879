// Import Components
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

// Import Views
import Auth from './views/auth'
import GoogleAuth from './views/auth/googleAuth'
import MobileNumbers from './views/mobileNumbers'
import Authenticators from './views/authenticators'
import ActivateAccount from './views/activateAccount'
import Settings from './views/settings'

// Import Components
import PrivateRoute from './components/PrivateRoute';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/authenticate" component={Auth} />
        <Route exact path="/authenticate/google" component={GoogleAuth} />
        <Route exact path="/activate/:code?" component={ActivateAccount} />
        <PrivateRoute exact path="/" component={MobileNumbers} />
        <PrivateRoute exact path="/mobiles" component={MobileNumbers} />
        <PrivateRoute exact path="/authenticators" component={Authenticators} />
        <PrivateRoute exact path="/settings" component={Settings} />
      </Switch>
    </Router>
  )
};

export default App;
