import React, { useEffect, useState, useContext } from 'react';
import MoonLoader from "react-spinners/MoonLoader";

// Import Components
import Modal from '../../../components/Modal'

// Import Modules
import { postApi } from '../../../modules/api'

// Import Contexts
import { OrgContext } from '../../../contexts/orgContext'

// Logo Render
export default function AddUserPopup ({ toggleMethod, postSubmitMethod }) {
  const { state, dispatch } = useContext(OrgContext)
  const [ newUser, updateNewUser ] = useState({
    firstName: '',
    lastName: '',
    email: '',
    role: 'member'
  })

  const handleChange = (key, e) => updateNewUser({ ...newUser, [key]: e.target.value })

  const createUser = (event) => {
    dispatch({ type: 'LOADING'})
    postApi('addUserToOrganisation', newUser)
      .then((res) => {
        dispatch({ type: "SUCCESS" })
        toggleMethod(false)
        postSubmitMethod()
      })
      .catch((err) => {
        dispatch({ type: "FAILURE", payload: err})
      })
    event.preventDefault()
    
  }

  return (
    <Modal toggleMethod={toggleMethod}>
      <div className="px-4 pt-5 pb-4 bg-primary sm:p-6 sm:pb-4">
        <form className="w-full max-w-lg" onSubmit={createUser}>
          <div className="flex flex-wrap mb-6 -mx-3">
            <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0">
              <label className="block mb-2 text-xs font-bold leading-normal tracking-wide uppercase text-primaryText" for="grid-first-name">
                First Name
              </label>
              <input onChange={(e) => handleChange('firstName', e)} className="block w-full px-4 py-3 mb-3 leading-tight bg-gray-200 border border-red-500 rounded appearance-none text-primaryText focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane" />
            </div>
            <div className="w-full px-3 md:w-1/2">
              <label className="block mb-2 text-xs font-bold leading-normal tracking-wide uppercase text-primaryText" for="grid-last-name">
                Last Name
              </label>
              <input onChange={(e) => handleChange('lastName', e)} className="block w-full px-4 py-3 leading-tight bg-gray-200 border border-gray-200 rounded appearance-none text-primaryText focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Doe" />
            </div>
          </div>
          <div className="flex flex-wrap mb-2 -mx-3">
            <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0">
              <label className="block mb-2 text-xs font-bold leading-normal tracking-wide uppercase text-primaryText" for="grid-city">
                Email
              </label>
              <input onChange={(e) => handleChange('email', e)} className="block w-full px-4 py-3 leading-tight bg-gray-200 border border-gray-200 rounded appearance-none text-primaryText focus:outline-none focus:bg-white focus:border-gray-500" id="grid-email" type="text" placeholder="jane@example.com" />
            </div>
            <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0">
              <label className="block mb-2 text-xs font-bold leading-normal tracking-wide uppercase text-primaryText" for="grid-state">
                Role
              </label>
              <div className="relative">
                <select onChange={(e) => handleChange('role', e)}  className="block w-full px-4 py-3 pr-8 leading-tight bg-gray-200 border border-gray-200 rounded appearance-none text-primaryText focus:outline-none focus:bg-white focus:border-gray-500" id="grid-role">
                  <option>Member</option>
                  <option>Admin</option>
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none text-primaryText">
                  <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                </div>
              </div>
            </div>
            <div className="w-full px-3 mt-4 md:flex md:justify-end">
              <button 
                type="submit"
                className="relative flex items-center justify-center w-full h-full px-4 py-2 text-sm font-medium leading-normal leading-5 text-white transition duration-150 ease-in-out border border-transparent rounded-md group bg-primaryText hover:bg-primaryText focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
              >
                { state.loading 
                  ? (
                    <div className="flex items-center h-full mx-auto">
                      <MoonLoader color={"#F5F5F5"} css={"display: flex; align-items: center;"} size={15} /> 
                    </div>
                  )
                  : "Create" 
                }
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  )
}
