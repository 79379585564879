import React, { Fragment, useContext, useMemo, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { ExpandMore, AddBox } from '@material-ui/icons';

// Import Context
import { AuthContext } from '../../../../contexts/authContext'
import { OrgContext } from '../../../../contexts/orgContext'

// Import Utilities
import { postApi } from '../../../../modules/api'

// Import Components
import CreateOrganisationComponent from './createOrganisationComponent'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function OrganisationSelector() {
  const { state: authState, dispatch: authDispatch } = useContext(AuthContext)
  const { state: orgState, dispatch: orgDispatch } = useContext(OrgContext)
  const [displayPopup, setDisplayPopup] = useState(false)

  const selectOrganisation = (payload) => {
    orgDispatch({ type: 'LOADING' })
    postApi('changeOrganisation', { orgId: payload.orgId })
      .then(res => {
        authDispatch({ type: "AUTH_SUCCESS", payload: res.body })
        window.location.reload()
      })
      .catch(err => authDispatch({ type: 'ERROR', payload: err }))
  }

  // setCurrentOrganisation(orgState.organisations.filter((org) => org.orgSlug === authState.orgSlug)[0] || {})
  const currentOrganisation = useMemo(() => orgState.organisations.filter((org) => org.orgSlug === authState.orgSlug)[0] || { name: "Select Organisation"}, [orgState.organisations, authState.orgSlug]);

  return (
    <div className="flex items-center w-full flew-row">
      <div className="hidden mr-2 text-white sm:flex">Organisation:</div>
      <Listbox value={currentOrganisation} onChange={selectOrganisation}>
        {({ open }) => (
          <>
            <Listbox.Button className="relative w-full py-2 pl-3 pr-10 leading-normal text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <span className="inline-flex w-full truncate">
                <span className="truncate">{currentOrganisation.name}</span>
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ExpandMore className="w-5 h-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            {/* List Organisations */}
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="absolute right-0 z-10 pb-1 mx-4 mt-1 overflow-auto text-base leading-normal bg-white rounded-md shadow-lg sm:mx-0 max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                {orgState.organisations.map((organisation) => (
                  <Listbox.Option
                    key={organisation.orgSlug}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-primary bg-primaryText' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-3 pr-3'
                      )
                    }
                    value={organisation}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex justify-between">
                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'truncate')}>
                            {organisation.name}
                          </span>
                          <span className={classNames(active ? 'text-primary' : 'text-gray-500', 'ml-2 truncate')}>
                            {organisation.orgSlug}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}

                {/* Create Organisation Component */}
                <div className={classNames(orgState.organisations.length ? "" : "w-24 h-12", "rounded-b-md flex justify-center")}>
                  <button className="flex items-center text-sm leading-normal text-center" onClick={() => setDisplayPopup(true)}> 
                    <AddBox /> 
                    Create Organisation
                  </button>
                </div>
              </Listbox.Options>
            </Transition>
          </>
        )}
      </Listbox>

      {displayPopup && <CreateOrganisationComponent toggleMethod={setDisplayPopup} selectOrganisation={selectOrganisation} />}
    </div>
  )
}
