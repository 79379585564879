import React, { createContext, useReducer, useEffect } from 'react';

import { getApi } from '../modules/api'

export const OrgContext = createContext({});

const initialState = {
  loading: false,
  error: null,
  organisations: [],
  users: []
}

const OrgProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch(action.type) {
      case 'LOADING':
        return { ...state, loading: true}
      case 'SUCCESS':
      return { ...state, loading: false}
      case 'ERROR':
        return { ...state, loading: false, error: action.payload}
      case 'RETRIEVE_ORGANISATIONS': 
        return {
          ...state, 
          organisations: action.payload,
          loading: false
        }
      case "ADD_ORGANISATION": 
        return { 
          ...state,
          organisations: [ ...state.organisations, action.payload],
          loading: false
        }
      case 'STORE_USERS_IN_ORGANISATION':
        return { 
          ...state,
          users: action.payload,
          loading: false
        }
      case 'UPDATE_STORED_USERS':
        const { userId } = action.payload
        const newUsers = state.users.filter((user) => user.userId !== userId )
        return {
          ...state,
          users: newUsers,
          loading: false,
        }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: 'LOADING' })
    getApi('getOrganisations')
      .then(res =>  dispatch({ type: "RETRIEVE_ORGANISATIONS", payload: res.body.organisations }))
      .catch(err => dispatch({ type: 'ERROR', payload: err }))
  }, [])

  return (
    <OrgContext.Provider value={{ state, dispatch }}>
      {children}
    </OrgContext.Provider>
  )
};

export default OrgProvider
