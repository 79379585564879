import request from 'superagent';
import jwt from 'jsonwebtoken'

const URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : 'http://localhost:4000/dev';

const errorMessage = (obj) => obj?.response?.body?.message || "Sorry, something went wrong"

export const getApi = (path, payload, headers) => {
  return checkAuthStatus(path, () => new Promise((resolve, reject) => {
    const { accessToken } = getTokens()
    return request.get(`${URL}/${path}`)
      .responseType()
      .query(payload)
      .set(headers || {})
      .set('Authorization', `bearer ${accessToken}`)
      .then(res => {
        return resolve(res);
      })
      .catch((err) => {
        console.log("What is the error: ", err.message)
        reject(errorMessage(err));
      });
  }));
}

export const postApi = (path, body) => {
  return checkAuthStatus(path, () => new Promise((resolve, reject) => {
    const { accessToken } = getTokens()
    return request.post(`${URL}/${path}`)
      .set('Accept', 'application/json')
      .set('Authorization', `bearer ${accessToken}`)
      .send(body)
      .then(res => {
        return resolve(res);
      })
      .catch((err) => {
        reject(errorMessage(err));
      });
  }));
}

export const putApi = (path, headers, body) => {
  return checkAuthStatus(path, () => new Promise((resolve, reject) => {
    const { accessToken } = getTokens()
    return request.put(`${URL}/${path}`)
      .set(headers)
      .set('Authorization', `bearer ${accessToken}`)
      .send(body)
      .then(res => {
        return resolve(res);
      })
      .catch((err) => {
        reject(errorMessage(err));
      });
  }));
}

export const deleteApi = (path, headers) => {
  return checkAuthStatus(path, () => new Promise((resolve, reject) => {
    const { accessToken } = getTokens()
    return request.delete(`${URL}/${path}`)
      .set(headers)
      .set('Authorization', `bearer ${accessToken}`)
      .then(res => {
        return resolve(res);
      })
      .catch((err) => {
        reject(errorMessage(err));
      });
  }));
}

export const externalApi = (url, method, responseType = null, headers = {}, body = {}) => {
  return new Promise((resolve, reject) => {
    if (url && method) {
      switch (method) {
        case 'GET': {
          return request.get(url)
            .responseType(responseType)
            .set(headers)
            .then(res => {
                return resolve(res);
            })
            .catch(err => {
                reject('CustomApi GET | error found: ', err);
            });
        }

        case 'DELETE': {
          return request.delete(url)
            .set(headers)
            .then(res => {
                return resolve(res);
            })
            .catch(err => {
                reject('CustomApi DELETE | error found: ', err);
            });
        }

        case 'POST': {
          return request.post(url)
            .set(headers)
            .send(body)
            .then(res => {
                return resolve(res);
            })
            .catch(err => {
                reject('CustomApi POST | error found: ', err);
            });
        }

        case 'PUT': {
          return request.put(url)
            .set(headers)
            .send(body)
            .then(res => {
                return resolve(res);
            })
            .catch(err => {
                reject('CustomApi PUT | error found: ', err);
            });
        }

        default:
          return null;
      }
    }

    return reject('no url or method specified');
  });
};

// Private 

const getTokens = () => { return {
  accessToken: localStorage.getItem('accessToken'),
  refreshToken: localStorage.getItem('refreshToken')
}}

const checkAuthStatus = (path, cb) => {
  const publicRoutes = ['login', 'register', 'refreshToken', 'login/google', 'activate']
  if(publicRoutes.includes(path)) return cb()
  try {
    const { accessToken, refreshToken } = getTokens()
    if(accessToken && !(Date.now() >= jwt.decode(accessToken).exp * 1000)) {
      return cb()
    } else if ( refreshToken && !(Date.now() >= jwt.decode(refreshToken).exp * 1000)) {
      // Check if refreshToken is expired | Our API will validate tokens on the server
      return postApi('refreshToken', { refreshToken }).then(({ body }) => {
        localStorage.setItem('accessToken', body.accessToken)
        localStorage.setItem('refreshToken', body.refreshToken)
        return cb()
      })
    }
    throw new Error("Something went wrong with the tokens")
  } catch (err) {
    console.log("Error Checking Auth Status: ", err.message)
    localStorage.clear()
    window.location.reload()
  }
}
