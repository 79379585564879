import React from "react"
import MessageCard from './messageCard'

import { AppContext } from '../../../contexts/appContext'

const MessageColumn = () => {
  const { state, dispatch  } = React.useContext(AppContext)

  const selectMessageCard = (message) => {
    dispatch({ type: 'SELECT_ACTIVE_MESSAGE', payload: message })
  }
  
  return (
    <div className="flex flex-col border-r-2 flex-2 w-80">
      <div className="flex flex-col min-h-screen bg-white">
        <div className="flex flex-col overflow-y-auto">
          {!state.messages || !state.messages.length
            ? <MessageCard message={{ 
              content: "No messages available yet",
              sender: '',
              time: '' 
            }} /> 
            : state.messages.map((message, index) => {
              return (
                <MessageCard 
                  key={index}
                  message={message} 
                  onClick={() => selectMessageCard(message)} 
                  selected={state.activeMessage?.messageId === message.messageId} 
                />
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default MessageColumn
