import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

// Import components
import OrganisationSelector from './components/organisationSelector'
import LogoutButton from '../LogoutButton'
import InitialsIcon from '../InitialsIcon'

import OrgProvider from '../../contexts/orgContext'

import Logo from '../Logo'

export default function Navbar (props) {
  // Declare Hooks
  const [displayProfileDropdown, toggleProfileDropdown] = useState(false)
  const [displayMobileProfileDropdown, toggleMobileProfileDropdown ] = useState(false)
  const wrapperRef = useRef(null);

  // Dismiss dropdown if you click outside
  useEffect(() => {
    const handleClickOutside = e => {
      if(displayProfileDropdown) {
        if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
          toggleProfileDropdown(false)
        }
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [wrapperRef, displayProfileDropdown]);

  const ProfileDropdown = () => {
    return (
      <div ref={wrapperRef} className="relative ml-3">
        <div>
          <button onClick={() => toggleProfileDropdown(!displayProfileDropdown)} className="flex items-center max-w-xs text-sm bg-gray-800 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white" id="user-menu" aria-haspopup="true">
            <span className="sr-only">Open user menu</span>
            <InitialsIcon />
          </button>
        </div>
        { displayProfileDropdown && (
          <div className="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
            {/* <Link to="/profile" className="block px-4 py-2 text-sm leading-normal text-gray-700 hover:bg-gray-100" role="menuitem">Your Profile</Link>  */}
            <Link to="/settings" className="block px-4 py-2 text-sm leading-normal text-gray-700 hover:bg-gray-100" role="menuitem">Settings</Link>
            <LogoutButton />
          </div>
        )}
      </div>
    )
  }

  const MobileToggle = () => {
    return (
      <div className="flex -mr-2 md:hidden">
        <button onClick={() => toggleMobileProfileDropdown(!displayMobileProfileDropdown)} className="inline-flex items-center justify-center p-2 text-gray-400 bg-gray-800 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
          <span className="sr-only">Open main menu</span>
          <svg className="block w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#F46036" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
          <svg className="hidden w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#F46036" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    )
  }

  const MobileMenu = () => {
    // Mobile menu, toggle classes based on menu state.
    // Open: "block", closed: "hidden"
    return displayMobileProfileDropdown && (
      <div className="px-2 mt-3 space-y-1">
        <Link to="/settings" className="block px-3 py-2 text-base font-medium leading-normal text-gray-400 rounded-md hover:text-white hover:bg-gray-700">Settings</Link>
        <LogoutButton />
      </div>
    )
  }
  
  const activeLinkStyles = 'font-bold bg-white rounded-md text-primaryText'
  const inactiveLinkStyles = 'font-medium text-gray-300 rounded-md hover:bg-gray-700 hover:text-white'
  
  const mobilesLink = '/mobiles'
  const authenticatorLink = '/authenticators'

  return (
    <div>
      <OrgProvider>
        <nav className="bg-primary">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16">
              {/* Left Nav */}
              <div className="flex items-center">
                {/* Logo */}
                {/* flex items-baseline ml-10 space-x-4 */}
                <div className="flex items-center justify-between w-auto h-12 mx-auto">
                  <Link id={"Logo"} to={mobilesLink}><Logo contrast="white"/></Link>

                  <div id={"NavRoutes"} className="flex items-center w-1/2 ml-4 sm:w-full sm:ml-10">
                    <Link to={mobilesLink} className={`px-3 py-1 sm:py-2 my-1 leading-normal text-sm ${window.location.pathname.includes(mobilesLink) || window.location.pathname == '/' ? activeLinkStyles : inactiveLinkStyles}`}>Mobile Numbers</Link>
                    {/* <Link to={authenticatorLink} className={`px-3 py-1 sm:py-2 my-1 leading-normal text-sm ${window.location.pathname.includes(authenticatorLink) ? activeLinkStyles : inactiveLinkStyles}`}>Authenticators</Link> */}
                  </div>
                </div>
              </div>

              {/* Right Nav */}
              <div className="">
                <div className="items-center hidden ml-4 md:flex md:ml-6">
                  <OrganisationSelector />
                  <ProfileDropdown />
                </div>

                <MobileToggle />
              </div>
            </div>

            <div className="block pb-4 sm:hidden">
              <OrganisationSelector />
            </div>
          </div>

          <MobileMenu />
        </nav>
      </OrgProvider>
    </div>
  )
}
