import React from "react"

const NumberCard = ({ selected, mobileNumber, onClick }) => {
  const selectedCss = selected 
    ? 'min-w-full text-primary bg-white'
    : ''

  const availableCssButton = mobileNumber.mobileNumber 
    ? 'hover:bg-white hover:text-primary hover:bg-gray-50 text-white' 
    : 'cursor-not-allowed text-white'

  return (
    <button onClick={onClick} disabled={!mobileNumber.mobileNumber} href="#" className={`${selectedCss} ${availableCssButton} focus:outline-none min-w-full group flex items-center px-2 py-2 text-sm font-medium rounded-md`}>
      { mobileNumber.mobileNumber 
        ? (
          // Mobile Number Allocated
          <div className="flex items-center flex-1">
            <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 w-6 h-6 mr-3 text-primaryText" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
            </svg> 
            <div>{mobileNumber.mobileNumberDisplay || mobileNumber.mobileNumber}</div>
          </div>
        )
        : (
          // Mobile Number Pending Assignment
          <div className="flex items-center flex-1">
            <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 w-6 h-6 mr-3 text-primaryText" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <div>{mobileNumber.mobileNumberDisplay || 'Pending System Allocation'}</div>
          </div>
        )
      }
      {/* <button className="focus:outline-none" onClick={() => console.log("Opening settings")}>
        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
      </button> */}
    </button>
  )
}

export default NumberCard
