import React, { useState, useMemo } from 'react';
import { Group, CreditCard, AccountCircle } from '@material-ui/icons';

// Import Components
import BillingTab from "./billing"
import AccountTab from './account'
import OrganisationTab from "./organisation"

export default function SettingsPage () {
  const [ navigationTabs, updateNavigationTabs ] = useState([
    { name: 'Account', component: <AccountTab />, icon: AccountCircle, active: true },
    { name: 'Organisation', component: <OrganisationTab />, icon: Group, active: false },
    { name: 'Billing', component: <BillingTab />, icon: CreditCard, active: false },
  ])

  const changeTab = (newTab) => updateNavigationTabs(
    navigationTabs.map( navTab => {
      return {
        ...navTab,
        active: navTab.name === newTab.name || false
      }
    })
  )

  const renderActiveTab = useMemo(() => navigationTabs.filter(tab => tab.active)[0].component || (<></>), [navigationTabs]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <div>
      <div className="flex flex-col justify-center px-6 space-y-6 sm:flex-row lg:col-span-9">
        <aside className="w-full pb-3 mt-6 mr-0 border-b-4 sm:w-1/5 lg:col-span-3 sm:border-r-2 sm:border-primary sm:mr-4 sm:pb-0 sm:border-b-0">
          <nav className="flex justify-center h-full space-y-0 sm:block sm:justify-start sm:space-y-1">
            {navigationTabs.map((item) => (
              <button
                key={item.name}
                onClick={() => changeTab(item)}
                className={classNames(
                  item.active
                    ? 'bg-teal-50 border-transparent text-primaryText'
                    : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                  'group border-l-4 sm:px-0 px-3 py-2 flex items-center leading-normal text-sm font-medium focus:outline-none'
                )}
                aria-current={item.current ? 'page' : undefined}
              >
                <item.icon
                  className={classNames(
                    item.current
                      ? 'text-teal-500 group-hover:text-teal-500'
                      : 'text-gray-400 group-hover:text-gray-500',
                    'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                  )}
                  aria-hidden="true"
                />
                <span className="truncate">{item.name}</span>
              </button>
            ))}
          </nav>
        </aside>

        <div className="w-full sm:w-4/5">
          {renderActiveTab}
        </div>
      </div>
    </div>
  )
}
