import React, { useState, useEffect } from 'react';
import PropagateLoader from "react-spinners/PropagateLoader";
import { getApi } from '../../modules/api.js'
import GoogleIcon from './assets/google-icon.png'

const Login = ({ authState, toggleAction, onSubmit }) => {
  const { error: parentError, loading } = authState
  const [ error, setError] = useState(parentError)
  const [formValues, updateFormValues] = useState({ email: '', password: '' })
  const [invalidKeys, setInvalidKeys] = useState([])
  const requiredKeys = ['email', 'password']

  // This avoids the asynchronous nature of setState || useState
  useEffect(() => { 
    if(error !== parentError) setError(parentError) 
  }, [setError, error, parentError])

  // This will check if there are missing keys. If there are, we add
  // a necessary animation and then remove it along with any errors.
  const communicateMissingKey = (key) => {
    !invalidKeys.includes(key) && invalidKeys.push(key)

    // Clear everything
    const timer = setTimeout(() => {
      const newKeys = invalidKeys.slice(invalidKeys.indexOf(key) + 1)
      setInvalidKeys(newKeys)
      if(!newKeys.length) {
        setError('')
        setInvalidKeys([])
      }
    }, 1000);
    
    return () => clearTimeout(timer);
  }

  // Define our handler methods. Change and Submit
  const handleInputChange = e => updateFormValues({ ...formValues, [e.target.name]: e.target.value})
  const handleSubmit = () => {
    Object.entries(formValues).map(([key, value]) => {
      if(!value && requiredKeys.includes(key)) {
        communicateMissingKey(key)
        return false
      }
      return true
    })

    invalidKeys.length ? setError('Must provide email and password') : onSubmit(formValues)
  }

  const handleGoogleSSO = (e) => {
    e.preventDefault()
    getApi('login/google')
      .then(res => {
        if (res.body["Location"]){
          window.location.href = res.body["Location"]
        }
      })
      .catch(err => {
        console.log("Found Error with Google SSO: ", err)
      })
  }
  
  //  TODO: Memoize? | Define our CSS for the input fields.
  const inputCss = (key) => {
    const defaultClass = "appearance-none my-1 rounded-none relative block w-full px-3 py-2 border placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
    const errorClass = "animate-shake border-8 border-error"
    switch(key) {
      case 'email':
      return `${defaultClass} my-1 rounded-t-md ${invalidKeys.includes(key) && errorClass}`

      case 'password':
      return `${defaultClass} rounded-b-md ${invalidKeys.includes(key) && errorClass}`
      
      default:
      return `${defaultClass}`
    }
  }

  const redirectHome = () => window.location = 'https://onetomany.dev'

  return (
    <div>
      <h2 className="my-3 text-2xl font-extrabold leading-normal text-center text-white">
        Please login below
      </h2>

      <div className="rounded-md shadow-sm">
        <form>
          <input autoComplete="on" aria-label="Email address" name="email" type="email" onChange={handleInputChange} className={inputCss('email')} placeholder="Email address" />
          <input autoComplete="on" aria-label="Password" name="password" type="password" onChange={handleInputChange} className={inputCss('password')} placeholder="Password" />
        </form>
      </div>

      <div className={`${!error && 'mt-6'} flex flex-col`}>
        {
          error && <div className="leading-normal text-md text-error">
            {error}
          </div>
        }
        <div className="text-sm leading-normal text-white">
          {"Haven't got Beta Access? "}
          <button onClick={redirectHome} className="font-medium transition duration-150 ease-in-out text-primaryText hover:text-primaryText focus:outline-none focus:underline">
            Subscribe Here
          </button>
        </div>
      </div>

      <div className="mt-6">
        <div className="flex flex-col items-center justify-center h-12">
          <button 
            onClick={handleSubmit} 
            disabled={loading || false} 
            className="relative flex items-center justify-center w-full h-full px-4 py-2 text-sm font-medium leading-normal text-white transition duration-150 ease-in-out border border-transparent rounded-md group bg-primaryText hover:bg-primaryText focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
          >
            { loading 
              ? (
                <div className="flex items-center h-full mx-auto">
                  <PropagateLoader color={"#F5F5F5"} loading={loading} css={"display: flex; align-items: center;"} size={15} /> 
                </div>
              )
              : "Login" 
            }
          </button>
        </div>
      </div>
      <hr className="w-3/4 mx-auto my-4 border-t border-gray-200" aria-hidden="true" />
      <div className="mt-6">
        <div className="flex flex-col items-center justify-center h-12">
          <button 
            onClick={handleGoogleSSO} 
            className="relative flex items-center justify-center w-full h-full px-4 py-2 text-sm font-medium leading-normal transition duration-150 ease-in-out bg-white border border-transparent rounded-md text-primary group hover:bg-white focus:outline-none focus:shadow-outline-indigo active:bg-indigo-700"
          >
            <img className="w-6 h-6 flex-3" src={GoogleIcon} alt="google logo" />
            <div className="flex-1">Login with Google</div>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Login





