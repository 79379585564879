import React, { createContext, useReducer, useEffect } from 'react';
import jwt from 'jsonwebtoken'

import { getApi } from '../modules/api'

export const UserContext = createContext({});

const initialState = () => {
  return {
    loading: false,
    error: null,
    firstName: '',
    lastName: '',
  }
}

const UserProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch(action.type) {
      case 'LOADING':
        return { ...state, loading: true}
      case 'SUCCESS':
      return { ...state, loading: false}
      case 'ERROR':
        return { ...state, loading: false, error: action.payload}
      case 'RETRIEVE_USER': 
        return {
          ...state,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName, 
          email: action.payload.email,
          loading: false
        }
      case 'UPDATE_USER' :
        return {
          ...state,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          email: action.payload.email,
          loading: false
        }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState());

  useEffect(() => {
    dispatch({ type: 'LOADING' })
    getApi('getUser')
      .then(res => dispatch({ type: "RETRIEVE_USER", payload: res.body }))
      .catch(err => dispatch({ type: 'ERROR', payload: err }))
  }, [])

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  )
};

export default UserProvider
