import React from 'react'

const Authenticators = () => {
  return (
    <div>
      Authenticators
    </div>
  )
}

export default Authenticators
