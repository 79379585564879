import React, { useEffect, useContext } from 'react';
import PropagateLoader from "react-spinners/PropagateLoader";
import { useHistory, useParams } from 'react-router-dom'

// Import Contexts
import { AuthContext } from '../../contexts/authContext'

// Import Components
import Logo from '../../components/Logo'

// Import Modules
import { getApi } from '../../modules/api'

const ActivateAccount = () => {
  const { state, dispatch } = useContext(AuthContext)
  const history = useHistory()
  const { code } = useParams()

  useEffect(() => {
    dispatch({ type: 'LOADING' })
    getApi(`activate`, { code }).then(({ body: { accessToken, refreshToken }}) => {
      dispatch({ type: 'AUTH_SUCCESS', payload: { accessToken, refreshToken } })
      history.push('/mobiles')
    }).catch(err => {
      dispatch({ type: 'ERROR', payload: err })
    })
  }, [ code, history, dispatch ])

  return (
    <div className="flex items-center justify-center min-h-screen px-4 py-12 bg-gray-50 sm:px-6 lg:px-8">
      <div className="flex flex-col items-center justify-center p-16 border-2 border-black rounded-lg shadow-2xl bg-primary">
        <div className="flex w-auto h-12 mx-auto"><Logo contrast="white"/></div>
        { state.error ? (
          <div className="flex flex-col items-center justify-center">
            <div className="text-white">
              {"🚨 Something went wrong! 🚨"}
            </div>
            <div className="flex text-center text-red-600">
              {state.error?.message}
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center pt-4 text-white">
            <PropagateLoader color={"#F5F5F5"} loading={true} css={"display: flex; align-items: center;"} size={10} /> 
          </div>
        )}
      </div>
    </div>
  )
}

export default ActivateAccount
