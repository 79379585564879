import React, { useContext, useState, useEffect } from 'react'
import MoonLoader from "react-spinners/MoonLoader";

import { UserContext } from '../../contexts/userContext.js'

import { postApi } from '../../modules/api.js'

const AccountTab = () => {
  const { state, dispatch } = useContext(UserContext)
  const [ formValues, updateFormValues ] = useState({ firstName: state.firstName, lastName: state.lastName, email: state.email })

  useEffect(() => {
    updateFormValues({ firstName: state.firstName, lastName: state.lastName, email: state.email })
  }, [ state.lastName, state.firstName, state.email ])

  const submitChanges = (e) => {
    e.preventDefault()
    const { firstName, lastName, email } = formValues;
    
    if(firstName && lastName) {
      dispatch({ type: 'LOADING' })
      postApi('updateUser', { firstName, lastName, email })
        .then(res => dispatch({ type: "UPDATE_USER", payload: { firstName, lastName, email } }))
        .catch(err => dispatch({ type: 'ERROR', payload: err }))
    }
  }

  const updateFormField = (e) => {
    const { value, id } = e.target
    updateFormValues({
      ...formValues,
      [id]: value
    })
  }
  return (
    <div 
      className="flex items-center justify-center w-full h-full"
    >
      {!state.loading
        ? (
          <form className="w-full max-w-lg" onSubmit={submitChanges}>
            <div className="flex flex-wrap mb-6">
              <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0">
                <label className="block mb-2 text-xs font-bold leading-normal tracking-wide uppercase text-primary" for="firstName">
                  First Name
                </label>
                <input onChange={updateFormField} value={formValues.firstName} className="block w-full px-4 py-3 leading-tight bg-gray-200 border rounded-md appearance-none text-primary focus:outline-none focus:bg-white" id="firstName" type="text" />
              </div>
              <div className="w-full px-3 md:w-1/2">
                <label className="block mb-2 text-xs font-bold leading-normal tracking-wide uppercase text-primary" for="lastName">
                  Last Name
                </label>
                <input onChange={updateFormField} value={formValues.lastName} className="block w-full px-4 py-3 leading-tight bg-gray-200 border rounded-md appearance-none text-primary focus:outline-none focus:bg-white" id="lastName" type="text" />
              </div>
            </div>
            <hr className="w-3/4 mx-auto my-4 border-t border-primary" aria-hidden="true" />
            <div className="flex flex-wrap mb-6">
              <div className="w-full px-3">
                <label className="block mb-2 text-xs font-bold leading-normal tracking-wide uppercase text-primary" for="email">
                  Email
                </label>
                <input 
                  value={formValues.email}
                  className="block w-full h-12 px-4 leading-tight bg-gray-200 border rounded-md appearance-none text-primary focus:outline-none focus:bg-white"
                  id="email" 
                  type="email" 
                  disabled={true}
                  onChange={updateFormField}
                />
              </div>
            </div>
            <div className="flex flex-wrap justify-end mb-6">
              <div className="w-full px-3 mb-6 md:w-1/4 md:mb-0">
                <button 
                  type={"submit"}
                  disabled={false} 
                  className="relative flex items-center justify-center w-full px-4 py-2 text-sm font-medium leading-normal text-white transition duration-150 ease-in-out border border-transparent rounded-md group bg-primaryText hover:bg-primaryText focus:outline-none"
                >
                  Update
                </button>
              </div>
            </div>
          </form>
        )
        : (
          <div className="relative flex items-center justify-center w-full h-full px-4 py-2 mx-auto text-sm font-medium leading-normal text-white transition duration-150 ease-in-out group">
            <MoonLoader color={"#F46036"} loading={true} css={"display: flex; align-items: center;"} size={30} />
          </div>
        )
      }
    </div>
  )
}

export default AccountTab;
