import React, { useContext } from 'react'
import { CheckCircleOutline } from '@material-ui/icons';
import PropagateLoader from "react-spinners/PropagateLoader";

// Import Context
import { AuthContext } from '../../contexts/authContext'
import BillingProvider, { BillingContext } from '../../contexts/billingContext'

// Import Utilities
import { postApi } from '../../modules/api'


const BillingTabContents = (props) => {
  const { state: billingState, dispatch: billingDispatch } = useContext(BillingContext)
  const { state: authState } = useContext(AuthContext)

  const addCard = () => {
    billingDispatch({ type: "LOADING"})
    postApi('addCardToOrganisation', {}).then(res => {
      window.location.href = res.body.url
      // billingDispatch({ type: "LOADING"})
    }).catch(err => {
      console.log("Found Error: ", err)
      billingDispatch({ type: "ERROR", payload: err })
    })
  }

  const AddCard = () => {
    return ( 
      <div className="col-span-12 sm:col-span-6">
        <p className="mt-1 text-sm leading-normal text-gray-500">
          Add a card below to unlock all of our features <strong className="font-bold text-black">, you may need to re-login </strong>
        </p>
        <p className="py-2 text-sm font-bold leading-normal text-black">
          We are currently still building the ability to remove your card. If you require this, please contact support
        </p>
        {
          authState.trialMode 
          ? 
          <button 
            onClick={addCard} 
            disabled={billingState.loading || false} 
            className="relative flex items-center justify-center w-full px-4 py-2 text-sm font-medium leading-normal leading-5 text-white transition duration-150 ease-in-out border border-transparent rounded-md group bg-primaryText hover:bg-primaryText focus:outline-none"
          >
            { billingState.loading 
              ? (
                <div className="flex items-center h-full mx-auto">
                  <PropagateLoader color={"#F5F5F5"} loading={billingState.loading} css={"display: flex; align-items: center;"} size={15} /> 
                </div>
              )
              : "Add Card" 
            }
          </button>
          : <button style={{ background: "#26B37E" }} className="relative flex items-center justify-center w-full px-4 py-2 text-sm font-medium leading-normal leading-5 text-white transition duration-150 ease-in-out border border-transparent rounded-md group focus:outline-none">
            <CheckCircleOutline />
          </button> 
        }
      </div>
    )
  }

  return (
    <div className="flex flex-col mx-6">
      <div>
        <h2 className="text-lg font-medium leading-normal text-gray-900">Billing</h2>
        <p className="mt-1 text-sm leading-normal text-gray-500">
          Manage your subscription and card details through Stripe
        </p>
      </div>

      <div className="w-full mt-6 sm:w-3/4">
        {AddCard()}
      </div>
    </div>
  )
}

export default function BillingTab(props) {
  return (
    <BillingProvider>
      <BillingTabContents {...props} />
    </BillingProvider>
  )
}
